import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '../local.storage.service';

@Injectable()
export class AnonymousGuard implements CanActivate {

  constructor(private localStorageService: LocalStorageService, private router: Router) {}
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.localStorageService.loadAuthenticatedUser()) {
      return true;
    } else {
      this.router.navigate(['/courses']);
      return false;
    }
  }
}