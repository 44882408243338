export class RegisterNewUserDto {
    constructor(name: string, email: string, password: string) {
        this.name = name;
        this.email = email;
        this.password = password;
    }
    name: string;
    email: string;
    password: string;
}

export class LoginWithSocialDto {
    constructor(token: string) {
        this.token = token;
    }
    token: string;
}

export class LoginUserDto {
    constructor(email: string, password: string) {
        this.email = email;
        this.password = password;
    }
    email: string;
    password: string;
}

export class AuthenticatedUserDto {
    email: string;
    name: string;
    authToken: string;
    phoneNumber: string;
    isPremium: string;
}

export class ResetPasswordDto {
    constructor(email: string) {
        this.email = email;
    }
    email: string;
}

export class CourseDto {
    id: string;
    departureCountry: string;
    departureCity: string;
    arrivalCountry: string;
    arrivalCity: string;
    startDate: string;
    calculatedDistance: string;
    priceValue: number;
    priceCurrency: string;
    cargoType: string;
    truckType: string;
    driverDocuments: string[];
    phoneNumber: string;
    status: string;
    isEditable: boolean;
    actions: AvailableActions;
}

export class AvailableActions {
    canEdit: boolean;
    canRegister: boolean;
    canUnregister: boolean;
}

export class ApiResponse<T> {
    message: string;
    code: number;
    data: T;
}

export class ApiResponsePaginated<T> {
    message: string;
    code: number;
    data: T[];
}

export class UpdateUserProfileDto {
    constructor(
        newUserName: string,
        // newUserAddress: string,
        // newUserLocation: string,
        // newUserAvailabilityStart: string,
        // newUserAvailabilityStop: string,
        newUserPhone: string,
        newDriverDocuments: string[]
    ) {
        this.newUserName = newUserName;
        // this.newUserAddress = newUserAddress;
        // this.newUserLocation = newUserLocation;
        // this.newUserAvailabilityStart = newUserAvailabilityStart;
        // this.newUserAvailabilityStop = newUserAvailabilityStop;
        this.newUserPhone = newUserPhone;
        this.newDriverDocuments = newDriverDocuments;
    }
    newUserName: string;
    // newUserAddress: string;
    // newUserLocation: string;
    // newUserAvailabilityStart: string;
    // newUserAvailabilityStop: string;
    newUserPhone: string;
    newDriverDocuments: string[]
}

export class UserProfile {
    userEmail: string;
    userName: string;
    userPhone: string;
    driverDocuments: string[];
}

export class UserAvailability {
    userEmail: string;
    userName: string;
    userPhone: string;
    driverDocuments: string[];
    userAddress: string;
    userLocation: string;
    userAvailabilityStart: string;
    userAvailabilityStop: string;
}

export class UpdateUserAvailabilityDto {
    constructor(
        newUserName: string,
        newUserAddress: string,
        newUserLocation: string,
        newUserAvailabilityStart: string,
        newUserAvailabilityStop: string,
        newUserPhone: string,
        newDriverDocuments: string[]
    ) {
        this.newUserName = newUserName;
        this.newUserAddress = newUserAddress;
        this.newUserLocation = newUserLocation;
        this.newUserAvailabilityStart = newUserAvailabilityStart;
        this.newUserAvailabilityStop = newUserAvailabilityStop;
        this.newUserPhone = newUserPhone;
        this.newDriverDocuments = newDriverDocuments;
    }
    newUserName: string;
    newUserAddress: string;
    newUserLocation: string;
    newUserAvailabilityStart: string;
    newUserAvailabilityStop: string;
    newUserPhone: string;
    newDriverDocuments: string[]
}

export interface Location {
    lat: number;
    lng: number;
}

export class Option {
    id: number;
    name: string;
}

export const DICT_DRIVER_DOCUMENTS: Option[] =
    [
        {
            id: 1,
            name: 'DD_CAT_B'
        },
        {
            id: 2,
            name: 'DD_CAT_C'
        },
        {
            id: 3,
            name: 'DD_CAT_C_AND_E'
        },
        {
            id: 4,
            name: 'DD_CAT_D'
        },
        {
            id: 5,
            name: 'DD_VRUS'
        },
        {
            id: 6,
            name: 'DD_VKAZ'
        },
        {
            id: 7,
            name: 'DD_VBY'
        },
        {
            id: 8,
            name: 'DD_ADR'
        },
        {
            id: 9,
            name: 'DD_CAT_C_AND_E_95'
        }
    ];

export class Dictionaries {
    public static DictDriverDocumentId(name: string): number {
        return DICT_DRIVER_DOCUMENTS.filter(x => x.name == name)[0].id;
    }

    public static DictDriverDocumentName(id: number): string {
        return DICT_DRIVER_DOCUMENTS.filter(x => x.id == id)[0].name;
    }
}

export class SubscriptionDto {
    id: string;
    price: string;
    startingDate: string;
    endingDate: string;
    status: string;
  }
  
  export class SubscriptionViewModel {
    id: string;
    price: string;
    startingDate: string;
    endingDate: string;
    status: string;
  }
  
  export class Subscription {
    id: string;
    type: string;
    price: string;
    status: string;
    begin: string;
    end: string;
  
    public static TYPE_MONTHLY: string = 'DST_MONTHLY';
    public static TYPE_INFINITE: string = 'DST_INFINITE';
    public static STATUS_NEW: string = 'DSS_NEW';
    public static STATUS_ACTIVE: string = 'DSS_ACTIVE';
    public static STATUS_EXPIRED: string = 'DSS_EXPIRED';
  
  }
  
  export class InvoiceData {
    subscriptionId: string;
    nip: string;
    name: string;
    address: string;
    zip: string;
    city: string;
  }