/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./failure.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./failure.component";
var styles_FailureComponent = [i0.styles];
var RenderType_FailureComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FailureComponent, data: {} });
export { RenderType_FailureComponent as RenderType_FailureComponent };
export function View_FailureComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "page-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "col-sm-6 offset-sm-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "card"], ["style", "margin-top: 50px !important"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "card-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "media"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("premium.payment.title")); _ck(_v, 7, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("premium.payment.failure")); _ck(_v, 14, 0, currVal_1); }); }
export function View_FailureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-failure", [], null, null, null, View_FailureComponent_0, RenderType_FailureComponent)), i1.ɵdid(1, 114688, null, 0, i3.FailureComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FailureComponentNgFactory = i1.ɵccf("app-failure", i3.FailureComponent, View_FailureComponent_Host_0, {}, {}, []);
export { FailureComponentNgFactory as FailureComponentNgFactory };
