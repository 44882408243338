import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivateComponent } from './activate.component';
import { ActivateRoutingModule } from './activate-routing.module';
import { SharedModule } from '../../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    // ActivateRoutingModule,
    RouterModule,
    SharedModule,
    TranslateModule
  ],
  declarations: [ActivateComponent]
})
export class ActivateModule { }
