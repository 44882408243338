import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillEditorModule } from 'ngx-quill-editor/index';
import { DataTableModule } from 'angular2-datatable';
import { HttpClientModule } from '@angular/common/http';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AgmCoreModule } from '@agm/core';
import { Secrets } from 'src/environments/secrets';
import { SelectModule } from 'ng-select';
import { UserAvailabilityComponent } from './user-availability/user-availability.component';
import { UserRoutingModule } from './user-routing.module';

@NgModule({
  declarations: [UserProfileComponent, UserAvailabilityComponent],
  imports: [
    CommonModule,
    UserRoutingModule,
    QuillEditorModule,
    FormsModule,
    ReactiveFormsModule,
    // UserProfileRoutingModule,
    SharedModule,
    SelectModule,
    HttpClientModule,
    DataTableModule,
    TranslateModule,
    AgmCoreModule.forRoot({apiKey: Secrets.GOOGLE_MAPS_API_KEY})
  ]
})
export class UserModule { }
