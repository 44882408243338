import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { ToastsService } from 'src/app/services/toasts.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LogsService } from '../logs.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

    constructor(
        private toastsService: ToastsService,
        private router: Router,
        private localStorageService: LocalStorageService,
        private translateService: TranslateService,
        private logs: LogsService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            this.logs.Error(err);
            if(err.status == 401) {
                this.toastsService.error(this.translateService.instant('auth.logout.success'));
                this.localStorageService.clearAuthenticatedUser();
                this.router.navigateByUrl('/');
            }
            throw err;
        }));
    }
}