<div id="pcoded" class="pcoded" [ngClass]="animateSidebar" [attr.nav-type]="navType" [attr.theme-layout]="themeLayout" [attr.horizontal-placement]="verticalPlacement"
  [attr.horizontal-layout]="verticalLayout" [attr.pcoded-device-type]="pcodedDeviceType" [attr.vertical-nav-type]="verticalNavType"
  [attr.vertical-effect]="verticalEffect" [attr.hnavigation-view]="vnavigationView" [attr.fream-type]="freamType" [attr.sidebar-img]="sidebarImg"
  [attr.sidebar-img-type]="sidebarImgType" [attr.layout-type]="layoutType" (window:resize)="onResize($event)">
  <!--<div class="pcoded-overlay-box"></div>-->
  <div class="pcoded-container navbar-wrapper">
    <nav class="navbar header-navbar pcoded-header" [attr.header-theme]="headerTheme" [attr.pcoded-header-position]="pcodedHeaderPosition">
      <div class="navbar-wrapper">

        <div class="row" style="text-align: center">
          <div class="col" *ngFor="let asideItems of menuItems.getAll()" style=" padding-right: 0px;">
            <a *ngIf="isAuthorized()" class="btn waves-effect ripple light" [routerLinkActive]="['active']" [routerLink]="['/', asideItems.main[0].state]">
              <span>
                <i class="{{ asideItems.main[0].icon }}"></i>
                <span class="hide-on-mobile">{{translate(asideItems.main[0].name)}}</span>

              </span>
            </a>
          </div>
          <div class="col">
            <a *ngIf="isAuthorized()" class="btn waves-effect ripple light" (click)="toggleShowSettings()">
              <span>
                <i class="feather icon-settings"></i>
                <span class="hide-on-mobile">{{translate('text.settings')}}</span>
              </span>
            </a>
          </div>
        </div>
        <div class="row" id="navSettingCard" *ngIf="showSettings" style="text-align: right;">
          <div *ngIf="isAuthorized()" class="col">
            <div>
              <a *ngIf="isLanguage('en')" (click)="setLang('pl')" class="btn waves-effect ripple light">
                <span>
                  <i class="feather icon-repeat"></i>
                  <span>Polski</span>
                </span>
              </a>
              <a *ngIf="isLanguage('pl')" (click)="setLang('en')" class="btn waves-effect ripple light">
                <span>
                  <i class="feather icon-repeat"></i>
                  <span>English</span>
                </span>
              </a>
            </div>
            <div>
              <a class="btn" style="text-transform: none; color: black; text-align: unset ">
                <span>{{currentUserName}}</span>
                <br/>
                <span>{{currentUserEmail}}</span>
              </a>
            </div>
            <div>
              <a class="btn waves-effect ripple light" (click)="logout()">
                <span>
                  <i class="feather icon-power"></i>
              <span>{{translate('text.logout')}}</span>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div class="row" id="navSettingCard" *ngIf="!isAuthorized()" style="text-align: right;">
          <div class="col">
            <div>
              <a *ngIf="isLanguage('en')" (click)="setLang('pl')" class="btn waves-effect ripple light">
                <span>
                  <i class="feather icon-repeat"></i>
                  <span>Polski</span>
                </span>
              </a>
              <a *ngIf="isLanguage('pl')" (click)="setLang('en')" class="btn waves-effect ripple light">
                <span>
                  <i class="feather icon-repeat"></i>
                  <span>English</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>


    <div class="pcoded-main-container" [style.margin-top]="headerFixedMargin">
      <!--  -->
      <div class="pcoded-wrapper">

        <div class="pcoded-content">
          <app-breadcrumbs></app-breadcrumbs>
          <div class="pcoded-inner-content">
            <!-- Main-body start -->
            <div class="main-body">
              <div class="page-wrapper">
                <router-outlet>
                  <title></title>
                  <app-spinner></app-spinner>
                </router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng2-toasty></ng2-toasty>