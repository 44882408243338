import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { LogsService } from 'src/app/services/logs.service';
import { UsersService } from 'src/app/services/users.service';

@Injectable()
export class PushSubscriptionService {

    constructor(
        private swPush: SwPush,
        private logs: LogsService,
        private usersService: UsersService
    ) {

    }

    public AskUserForPushSubscriptionIfNeeded() {
        if (this.swPush.isEnabled) {
            this.swPush.requestSubscription({
                serverPublicKey: 'BMjtLnoiQaupgYIJTz4MDyuOa4uSblDGFZfr4hZLrS5FOqUik1OL7mXbxOzAKso1Mw-8JCLhd-W8qfUECnKN1Kg'
            })
                .then(subscription => {
                    this.logs.Info('User allows subscriptions');
                    this.usersService.sendSubscriptionToServer(subscription).subscribe();
                })
                .catch(error => {
                    this.logs.Error('Subscription error:' + error);
                });
        }
    }
}