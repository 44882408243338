import * as Sentry from "@sentry/browser";
import { environment } from '../../environments/environment';
Sentry.init({
    dsn: "https://297a3bed7a254064b8d16bc0a6be5747@sentry.io/1450475",
    environment: environment.name
});
var LogsService = /** @class */ (function () {
    function LogsService() {
    }
    LogsService.prototype.ShouldSendToSentry = function () {
        return environment.name != 'DEV';
    };
    LogsService.ShouldSendToSentry = function () {
        return environment.name != 'DEV';
    };
    LogsService.prototype.Log = function (message) {
        var optionalParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            optionalParams[_i - 1] = arguments[_i];
        }
        console.log(message, optionalParams);
        if (this.ShouldSendToSentry()) {
            Sentry.captureMessage(message, Sentry.Severity.Info);
        }
    };
    LogsService.prototype.Error = function (message) {
        var optionalParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            optionalParams[_i - 1] = arguments[_i];
        }
        console.error(message, optionalParams);
        if (this.ShouldSendToSentry()) {
            Sentry.captureException(message.originalError || message);
        }
    };
    LogsService.prototype.Warn = function (message) {
        var optionalParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            optionalParams[_i - 1] = arguments[_i];
        }
        console.warn(message, optionalParams);
        if (this.ShouldSendToSentry()) {
            Sentry.captureMessage(message, Sentry.Severity.Warning);
        }
    };
    LogsService.prototype.Info = function (message) {
        var optionalParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            optionalParams[_i - 1] = arguments[_i];
        }
        console.info(message, optionalParams);
        if (this.ShouldSendToSentry()) {
            Sentry.captureMessage(message, Sentry.Severity.Info);
        }
    };
    LogsService.Log = function (message) {
        var optionalParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            optionalParams[_i - 1] = arguments[_i];
        }
        console.log(message, optionalParams);
        if (this.ShouldSendToSentry()) {
            Sentry.captureMessage(message, Sentry.Severity.Info);
        }
    };
    LogsService.Error = function (message) {
        var optionalParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            optionalParams[_i - 1] = arguments[_i];
        }
        console.error(message, optionalParams);
        if (this.ShouldSendToSentry()) {
            Sentry.captureException(message.originalError || message);
        }
    };
    return LogsService;
}());
export { LogsService };
