<section class="login-block">
  <!-- Container-fluid starts -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <!-- Authentication card start -->
        <div class="auth-box card">
          <div class="card-block">
            <div *ngIf="showInProgress()" class="auth-block">
              <h1>{{'auth.activate.in_progress_title' | translate}}</h1>
            </div>
            <div *ngIf="showSuccess()" class="auth-block">
              <h1>{{'auth.activate.success_title' | translate}}</h1>
              <a routerLink="/auth/login" class="auth-link">{{'auth.activate.success_login_link' | translate}}</a>
            </div>
            <div *ngIf="showError()" class="auth-block">
              <h1>{{'auth.activate.error_title' | translate}}</h1>
              <a routerLink="/auth/login" class="auth-link">{{'auth.activate.success_login_link' | translate}}</a>
            </div>
          </div>
        </div>
      </div>
      <!-- Authentication card end -->
    </div>
    <!-- end of col-sm-12 -->
  </div>
  <!-- end of container-fluid -->
</section>