import {Injectable} from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Lista kursów',
    main: [
      {
        state: 'courses',
        short_label: 'S',
        name: 'text.courses',
        type: 'link',
        icon: 'feather icon-list'
      }
    ]
  },
  {
    label: 'Użytkownik',
    main: [
      {
        state: 'user',
        short_label: 'CS',
        name: 'text.profile',
        type: 'link',
        icon: 'feather icon-user',
        target: true
      }
    ]
  },
  {
    label: 'Dostępność',
    main: [
      {
        state: 'availability',
        short_label: 'CS',
        name: 'text.availability',
        type: 'link',
        icon: 'feather icon-map',
        target: true
      }
    ]
  }
];


@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}
