import { Component, OnInit } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { UsersService } from 'src/app/services/users.service';
import { RegisterNewUserDto } from 'src/app/services/model';
import { ToastsService } from '../../../../services/toasts.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { EmailValidator } from 'src/app/services/validators/email.validator';
import { EqualPasswordsValidator } from 'src/app/services/validators/equalPasswords.validator';
import { TranslateService } from '@ngx-translate/core';
import { LogsService } from '../../../../services/logs.service';

@Component({
  selector: 'app-basic-reg',
  templateUrl: './basic-reg.component.html',
  styleUrls: [
    './basic-reg.component.scss'
  ]
})
export class BasicRegComponent implements OnInit {

  constructor(
    private userService: UsersService,
    private toastsService: ToastsService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private logs: LogsService
  ) { }

  public form: FormGroup;
  public termsAgreed: boolean;
  public submitted: boolean;

  ngOnInit() {
    document.querySelector('body').setAttribute('themebg-pattern', 'theme1');

    this.submitted = false;
    this.form = this.fb.group({
      // 'name': ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      'email': ['', Validators.compose([Validators.required, EmailValidator.validate, Validators.minLength(4)])],
      'passwords': this.fb.group({
        'password': ['', Validators.compose([Validators.required, Validators.minLength(8)])],
        'repeatPassword': ['', Validators.compose([Validators.required, Validators.minLength(8)])]
      }, { validator: EqualPasswordsValidator.validate('password', 'repeatPassword') })
    });
  }

  public register(): void {
    if (!this.submitted && this.form.valid) {

      if (!this.termsAgreed) {
        this.toastsService.error(this.translateService.instant('auth.register.terms_are_required'));
        return;
      }

      let name = this.translateService.instant('text.default_driver_name');
      let email = this.form.get('email').value;
      let password = this.form.get('passwords').get('password').value;

      // var dto = new RegisterNewUserDto('driver-mobile', 'driver-mobile@mailinator.com', 'testPassword');
      // var dto = new RegisterNewUserDto('driver-mobile', 'driver-mobile', 'testPassword');
      var dto = new RegisterNewUserDto(name, email, password);

      this.logs.Info('Registering email:' + email);
      this.submitted = true;
      this.userService.registerNewUser(dto).subscribe(res => {
        // this.logs.Log("> OK");
        // this.logs.Warn(res);
        this.toastsService.success(this.translateService.instant('auth.register.success'));
        this.submitted = false;
      }, e => {
        // this.logs.Log("> NOK");
        this.logs.Error(e);
        this.toastsService.error(this.translateService.instant('auth.register.error_title'));
        this.submitted = false;
      });
    }
  }
}
