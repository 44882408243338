import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse, CourseDto, ApiResponsePaginated } from 'src/app/services/model';
import { environment } from '../../environments/environment';

@Injectable()
export class CoursesService {

    constructor(
        private http: HttpClient,
        // private localStorage: LocalStorageService,
        // private router: Router
    ) {
        this.apiBaseUrl = environment.apiUrl;
    }

    private apiBaseUrl: string;

    getCoursesFromApi(): Observable<ApiResponsePaginated<CourseDto>> {
        return this.http.get<ApiResponsePaginated<CourseDto>>(this.apiBaseUrl + 'api/courses/browse');
    }

    registerForCourse(courseId: string): Observable<ApiResponse<CourseDto>> {
        return this.http.post<ApiResponse<CourseDto>>(this.apiBaseUrl + 'api/courses/' + courseId + '/register', null);
    }

    unregisterFromCourse(courseId: string): Observable<ApiResponse<CourseDto>> {
        return this.http.post<ApiResponse<CourseDto>>(this.apiBaseUrl + 'api/courses/' + courseId + '/unregister', null);
    }

}