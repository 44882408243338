<section class="login-block">
  <!-- Container-fluid starts -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <form [formGroup]="form" class="md-float-material form-material">
          <!-- <div class="text-center">
            <img src="assets/images/logo.png" alt="logo.png">
          </div> -->
          <div class="auth-box card">
            <div class="card-block">
              <div class="row m-b-20">
                <div class="col-md-12">
                  <h3 class="text-center txt-primary">{{'auth.register.form_title' | translate}}</h3>
                </div>
              </div>
              <div class="form-group form-primary" >
                <input formControlName="email" type="text" name="email" class="form-control" required="">
                <div class="messages text-danger" *ngIf="form.controls.email.touched && (form.controls.email.errors?.required || form.controls.email.errors?.minlength)">{{'auth.register.form_validation.email_required' | translate}}</div>
                <div class="messages text-danger" *ngIf="form.controls.email.touched && (!form.controls.email.errors?.required && form.controls.email.errors?.validateEmail)">{{'auth.register.form_validation.email_invalid' | translate}}</div>
                <span class="form-bar"></span>
                <label class="float-label">{{'auth.register.form_username' | translate}}</label>
              </div>
              <div class="row" formGroupName="passwords">
                <div class="col-sm-6">
                  <div class="form-group form-primary">
                    <input formControlName="password" type="password" name="password" class="form-control" required="">
                    <div class="messages text-danger" *ngIf="form.get('passwords').get('password').touched && form.get('passwords').get('password').errors?.required">{{'auth.register.form_validation.password_required' | translate}}</div>
                    <div class="messages text-danger" *ngIf="form.get('passwords').get('password').touched && (!form.get('passwords').get('password').errors?.required && form.get('passwords').get('password').errors?.minlength)">{{'auth.register.form_validation.password_invalid' | translate}}</div>
                    <span class="form-bar"></span>
                    <label class="float-label">{{'auth.register.form_password' | translate}}</label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group form-primary">
                    <input formControlName="repeatPassword" type="password" name="confirm-password" class="form-control" required="">
                    <div class="messages text-danger" *ngIf="form.get('passwords').get('repeatPassword').touched && form.get('passwords').get('repeatPassword').errors?.required">{{'auth.register.form_validation.repeatpassword_required' | translate}}</div>
                    <div class="messages text-danger" *ngIf="form.get('passwords').get('repeatPassword').touched && (!form.get('passwords').get('repeatPassword').errors?.required && form.get('passwords').errors?.passwordsEqual)">{{'auth.register.form_validation.passwords_notequal' | translate}}</div>
                    <span class="form-bar"></span>
                    <label class="float-label">{{'auth.register.form_password_config' | translate}}</label>
                  </div>
                </div>
              </div>
              <div class="row m-t-25 text-left">
                <div class="col-md-12">
                  <div class="checkbox-fade fade-in-primary">
                    <label>
                      <input type="checkbox" value="" [checked]="termsAgreed" (change)="termsAgreed = !termsAgreed">
                      <span class="cr"><i class="cr-icon fa fa-check txt-primary"></i></span>
                      <span class="text-inverse">{{'auth.register.form_terms_question' | translate}} <a href="https://jumperdriver.pl/terms.html">{{'auth.register.form_terms_link' | translate}}</a>&nbsp;&amp;&nbsp;<a href="https://jumperdriver.pl/privacy.html">{{'auth.register.form_privacy_link' | translate}}</a></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row m-t-30">
                <div class="col-md-12">
                  <button [disabled]="!form.valid" type="submit" class="btn btn-primary btn-md btn-block text-center m-b-20 ripple light" (click)="register()">{{'auth.register.button_register' | translate}}</button>
                </div>
              </div>
              <hr/>
              <div class="row">
                <div class="col-md-10">
                  <p class="text-inverse text-left"><a [routerLink]="['/auth/login']"><b>{{'auth.register.link_back_to_login' | translate}}</b></a></p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- end of col-sm-12 -->
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container-fluid -->
</section>
