import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoursesRoutingModule } from './courses-routing.module';
import { BrowseCoursesComponent } from './browse-courses/browse-courses.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [BrowseCoursesComponent],
  imports: [
    TranslateModule,
    CommonModule,
    CoursesRoutingModule
  ],
  // providers: [CoursesService]
})
export class CoursesModule { }
