import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { BrowseCoursesComponent } from './courses/browse-courses/browse-courses.component';
import { BasicRegComponent } from './theme/auth/registration/basic-reg/basic-reg.component';
import { ForgotComponent } from './theme/auth/forgot/forgot.component';
import { ActivateComponent } from './theme/auth/activate/activate.component';
import { UserProfileComponent } from './theme/user/user-profile/user-profile.component';
import { AnonymousGuard } from 'src/app/services/guards/anonymous.guard';
import { AuthorizedGuard } from './services/guards/authorized.guard';
import { SocialLoginComponent } from './theme/auth/login/social-login/social-login.component';
import { UserProfileGuard } from './services/guards/user-profile.guard';
import { UserAvailabilityComponent } from './theme/user/user-availability/user-availability.component';
import { PremiumGuard } from './services/guards/premium.guard';
import { SubscriptionComponent } from './premium/subscription/subscription.component';
import { SuccessComponent } from './premium/payment/success/success.component';
import { FailureComponent } from './premium/payment/failure/failure.component';

const routes: Routes = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full', canActivate: [AnonymousGuard] },
  { path: 'auth/registration', component: BasicRegComponent, canActivate: [AnonymousGuard] },
  { path: 'activate', component: ActivateComponent, canActivate: [AnonymousGuard] },
  { path: 'reset', component: ForgotComponent, canActivate: [AnonymousGuard] },
  { path: 'auth/login', component: SocialLoginComponent, canActivate: [AnonymousGuard] },
  { path: 'courses', component: BrowseCoursesComponent, canActivate: [AuthorizedGuard, UserProfileGuard] },
  { path: 'user', component: UserProfileComponent, canActivate: [AuthorizedGuard] },
  { path: 'availability', component: UserAvailabilityComponent, canActivate: [AuthorizedGuard, PremiumGuard] },
  { path: 'subscription', component: SubscriptionComponent, canActivate: [AuthorizedGuard] },
  { path: 'subscription/payment/success', component: SuccessComponent, canActivate: [AuthorizedGuard] },
  { path: 'subscription/payment/failure', component: FailureComponent, canActivate: [AuthorizedGuard] },
  { path: '**', redirectTo: '/auth/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
