import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastsService } from 'src/app/services/toasts.service';
import { UsersService } from 'src/app/services/users.service';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { EmailValidator } from 'src/app/services/validators/email.validator';
import { ResetPasswordDto } from 'src/app/services/model';
import { LogsService } from '../../../services/logs.service';
import { ActivatedRoute } from '@angular/router';
import { EqualPasswordsValidator } from 'src/app/services/validators/equalPasswords.validator';

enum ResetPasswordMode {
  Initiate = 1,
  Confirm
}

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  constructor(
    private translateService: TranslateService,
    private toastsService: ToastsService,
    private userService: UsersService,
    private fb: FormBuilder,
    private logs: LogsService,
    private route: ActivatedRoute,
  ) { }

  public mode: ResetPasswordMode;
  public form: FormGroup;
  public email: AbstractControl;
  public submitted: boolean;

  public formConfirmation: FormGroup;
  private confirmationEmail: string;
  private confirmationToken: string;

  public password: AbstractControl;
  public repeatPassword: AbstractControl;
  public passwords: FormGroup;

  ngOnInit() {
    document.querySelector('body').setAttribute('themebg-pattern', 'theme1');
    this.submitted = false;

    let parameterEmail = this.route.snapshot.queryParamMap.get('email');
    let parameterToken = this.route.snapshot.queryParamMap.get('token');

    if (parameterEmail && parameterToken) {
      this.confirmationEmail = parameterEmail;
      this.confirmationToken = parameterToken;
      this.mode = ResetPasswordMode.Confirm;
      this.formConfirmation = this.fb.group({
        //'email': ['', Validators.compose([Validators.required, EmailValidator.validate])],
        //'token': ['', Validators.compose([Validators.required, EmailValidator.validate])],
        'passwords': this.fb.group({
          'password': ['', Validators.compose([Validators.required, Validators.minLength(8)])],
          'repeatPassword': ['', Validators.compose([Validators.required, Validators.minLength(8)])]
        }, { validator: EqualPasswordsValidator.validate('password', 'repeatPassword') })
      });
      //this.token = this.formConfirmation.controls['token'];
      //this.email = this.formConfirmation.controls['email'];
      this.passwords = <FormGroup>this.formConfirmation.controls['passwords'];
      this.password = this.passwords.controls['password'];
      this.repeatPassword = this.passwords.controls['repeatPassword'];
    } else {
      this.mode = ResetPasswordMode.Initiate;
      this.form = this.fb.group({
        'email': ['', Validators.compose([Validators.required, EmailValidator.validate, Validators.minLength(4)])]
      });
      this.email = this.form.controls['email'];
    }
  }

  reset() {
    if (!this.submitted && this.form.valid) {

      let email = this.form.get('email').value;

      var dto = new ResetPasswordDto(email);

      this.logs.Info('Resetting for email:' + email);
      this.submitted = true;
      this.userService.resetPassword(dto).subscribe(res => {
        // this.logs.Log("> OK");
        // this.logs.Warn(res);
        this.toastsService.success(this.translateService.instant('auth.forgot.success'));
        this.submitted = false;
      }, e => {
        this.logs.Error(e);
        if(e.status == 400) {
          this.toastsService.error(
            this.translateService.instant('error_types.' + e.error.ErrorType + '.' + e.error.Message),
            this.translateService.instant('auth.forgot.error_title')
          );
        } else {
          this.toastsService.error(this.translateService.instant('auth.forgot.error_title'));
        }
        this.submitted = false;
      });
    }
  }

  public onSubmitConfirmation(): void {
    if (this.isConfirmMode()) {
      let password = this.formConfirmation.get('passwords').get('password').value;

      this.userService.resetPasswordConfirm(this.confirmationEmail, this.confirmationToken, password).subscribe(result => {
        let tmp = [];
        tmp.push(result);
        // LogsService.Log(tmp[0]);
        this.toastsService.success(this.translateService.instant('auth.forgot.confirm_success'));
      }, e => {
        this.logs.Error(e);
        if(e.status == 400) {
          this.toastsService.error(
            this.translateService.instant('error_types.' + e.error.ErrorType + '.' + e.error.Message),
            this.translateService.instant('auth.forgot.confirm_error_title')
          );
        } else {
          this.toastsService.error(this.translateService.instant('auth.forgot.confirm_error_title'));
        }
      });
    }
  }

  public isInitiateMode(): boolean {
    return this.mode == ResetPasswordMode.Initiate;
  }

  public isConfirmMode(): boolean {
    return this.mode == ResetPasswordMode.Confirm;
  }
}
