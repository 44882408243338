import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { MenuItems } from './shared/menu-items/menu-items';
import { BreadcrumbsComponent } from './layout/admin/breadcrumbs/breadcrumbs.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppUpdateService } from './app.update.service';
import { CoursesModule } from './courses/courses.module';
import { BasicRegModule } from './theme/auth/registration/basic-reg/basic-reg.module';
import { ForgotModule } from './theme/auth/forgot/forgot.module';
import { ActivateModule } from './theme/auth/activate/activate.module';
import { UserModule } from './theme/user/user.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastsService } from './services/toasts.service';
import { ToastyModule } from 'ng2-toasty';
import { LocalStorageService } from './services/local.storage.service';
import { AnonymousGuard } from './services/guards/anonymous.guard';
import { AuthorizedGuard } from './services/guards/authorized.guard';
import { SocialLoginModule } from './theme/auth/login/social-login/social-login.module';
import { TokenInterceptor } from './services/guards/token.interceptor';
import { CoursesService } from './services/courses.service';
import { UnauthorizedInterceptor } from './services/guards/unauthorized.interceptor';
import { ClientIdInterceptor } from './services/guards/client-id.interceptor';
import { LogsService } from './services/logs.service';
import { ApplicationErrorHandler } from './services/application-error.handler';
import { PushSubscriptionService } from 'src/app/services/push.subscription.service';
import { UserProfileGuard } from './services/guards/user-profile.guard';
import { GeocodeService } from 'src/app/services/geocode.service';
import { PremiumGuard } from './services/guards/premium.guard';
import { SubscriptionComponent } from './premium/subscription/subscription.component';
import { SuccessComponent } from './premium/payment/success/success.component';
import { FailureComponent } from './premium/payment/failure/failure.component';
import { FormBuilder, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PremiumSubscriptionService } from './services/premium.subscription.service';
import { TPayPaymentLinkGenerator } from './services/tpay.payment.link.generator';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    BreadcrumbsComponent,
    SubscriptionComponent,
    SuccessComponent,
    FailureComponent
  ],
  imports: [
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }
    ),
    ReactiveFormsModule,
    FormsModule,
    SocialLoginModule,
    BasicRegModule,
    ForgotModule,
    ActivateModule,
    UserModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    CoursesModule,
    ToastyModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true })
  ],
  schemas: [],
  providers: [
    {provide: ErrorHandler, useClass: ApplicationErrorHandler},
    MenuItems,
    LogsService,
    AppUpdateService,
    ToastsService,
    CoursesService,
    LocalStorageService,
    AnonymousGuard,
    AuthorizedGuard,
    PushSubscriptionService,
    GeocodeService,
    UserProfileGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientIdInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true
    },
    PremiumGuard,
    FormBuilder,
    PremiumSubscriptionService,
    TPayPaymentLinkGenerator
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
