import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppUpdateService } from './app.update.service';
import { TranslateService } from '@ngx-translate/core';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { LocalStorageService } from './services/local.storage.service';
import { LogsService } from './services/logs.service';
import { environment } from 'src/environments/environment';
import { MenuItems } from './shared/menu-items/menu-items';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss',
    '../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../node_modules/ng2-toasty/style-default.css',
    '../../node_modules/ng2-toasty/style-material.css'
  ],
  animations: [
    trigger('notificationBottom', [
      state('an-off, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('an-animate',
        style({
          overflow: 'hidden',
          height: AUTO_STYLE,
        })
      ),
      transition('an-off <=> an-animate', [
        animate('400ms ease-in-out')
      ])
    ]),
    trigger('mobileHeaderNavRight', [
      state('nav-off, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('nav-on',
        style({
          height: AUTO_STYLE,
        })
      ),
      transition('nav-off <=> nav-on', [
        animate('400ms ease-in-out')
      ])
    ])
  ]
})
export class AppComponent implements OnInit {
  title = 'Driver - pierwsza giełda dla kierowców';

  public showSettings: boolean = false;
  public currentUserName: string = '';
  public currentUserEmail: string = '';
  public animateSidebar: string = '';
  public headerFixedMargin: string = '';

  constructor(
    public menuItems: MenuItems,
    private router: Router,
    appUpdateService: AppUpdateService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private logs: LogsService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use('pl');

    this.navType = 'st2';
    this.themeLayout = 'vertical';
    this.verticalPlacement = 'left';
    this.verticalLayout = 'wide';
    this.pcodedDeviceType = 'desktop';
    this.verticalNavType = 'expanded';
    this.verticalEffect = 'shrink';
    this.vnavigationView = 'view1';
    this.freamType = environment.freamType;
    this.sidebarImg = 'false';
    this.sidebarImgType = 'img1';
    this.layoutType = environment.layoutType;

    this.headerTheme = environment.headerTheme;

    this.liveNotification = 'an-off';
    this.profileNotification = 'an-off';

    this.searchWidth = 0;

    this.navRight = 'nav-on';

    this.windowWidth = window.innerWidth;
    this.setHeaderAttributes(this.windowWidth);
  }

  // Navigation
  public navType: string;
  public themeLayout: string;
  public verticalPlacement: string;
  public verticalLayout: string;
  public pcodedDeviceType: string;
  public verticalNavType: string;
  public verticalEffect: string;
  public vnavigationView: string;
  public freamType: string;
  public sidebarImg: string;
  public sidebarImgType: string;
  public layoutType: string;

  public headerTheme: string;
  public pcodedHeaderPosition: string;

  public liveNotification: string;
  public liveNotificationClass: string;

  public profileNotification: string;
  public profileNotificationClass: string;

  public searchWidth: number;
  public searchWidthString: string;

  public navRight: string;
  public windowWidth: number;

  ngOnInit() {
    this.setCurrentUser();

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  // Navigation
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
    this.setHeaderAttributes(this.windowWidth);
  }

  setHeaderAttributes(windowWidth) {
    if (windowWidth < 992) {
      this.navRight = 'nav-off';
    } else {
      this.navRight = 'nav-on';
    }
  }

  toggleHeaderNavRight() {
    this.navRight = this.navRight === 'nav-on' ? 'nav-off' : 'nav-on';
  }

  hideHeaderNavRight() {
    // this.navRight = '';
  }

  toggleLiveNotification() {
    if (this.profileNotification === 'an-animate') {
      this.toggleProfileNotification();
    }

    this.liveNotification = this.liveNotification === 'an-off' ? 'an-animate' : 'an-off';
    this.liveNotificationClass = this.liveNotification === 'an-animate' ? 'show' : '';
  }

  toggleProfileNotification() {
    if (this.liveNotification === 'an-animate') {
      this.toggleLiveNotification();
    }

    this.profileNotification = this.profileNotification === 'an-off' ? 'an-animate' : 'an-off';
    this.profileNotificationClass = this.profileNotification === 'an-animate' ? 'show' : '';
  }

  notificationOutsideClick(ele: string) {
    if (ele === 'live' && this.liveNotification === 'an-animate') {
      this.toggleLiveNotification();
      this.hideHeaderNavRight();
    } else if (ele === 'profile' && this.profileNotification === 'an-animate') {
      this.toggleProfileNotification();
      this.hideHeaderNavRight();
    }
  }

  searchOn() {
    document.querySelector('#main-search').classList.add('open');
    const searchInterval = setInterval(() => {
      if (this.searchWidth >= 200) {
        clearInterval(searchInterval);
        return false;
      }
      this.searchWidth = this.searchWidth + 15;
      this.searchWidthString = this.searchWidth + 'px';
    }, 35);
  }

  searchOff() {
    const searchInterval = setInterval(() => {
      if (this.searchWidth <= 0) {
        document.querySelector('#main-search').classList.remove('open');
        clearInterval(searchInterval);
        return false;
      }
      this.searchWidth = this.searchWidth - 15;
      this.searchWidthString = this.searchWidth + 'px';
    }, 35);
  }

  setLang(lang: string) {
    this.logs.Log('setting language to: ' + lang);
    this.hideHeaderNavRight();
    this.translateService.use(lang);
    // this._globalLanguageEmitter.setLanguage(lang);
  }

  isLanguage(lang: string): boolean {
    return this.translateService.currentLang == lang;
  }

  isAuthorized(): boolean {
    return this.localStorageService.loadAuthenticatedUser() != null;
  }

  logout() {
    this.hideHeaderNavRight();
    this.showSettings = false;
    this.localStorageService.clearAuthenticatedUser();
    this.router.navigateByUrl('/');
  }

  translate(text: string): string {
    return this.translateService.instant(text);
  }

  toggleShowSettings(): void {
    this.showSettings = !this.showSettings;
  }

  private setCurrentUser(): void {
    var user = this.localStorageService.loadAuthenticatedUser();
    if(user) {
      this.currentUserName = user.name;
      this.currentUserEmail = user.email;
    }
  }
}
