import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export class Option {
  id: number;
  name: string;
}

@Component({
  selector: 'app-inverse-select',
  templateUrl: './inverse-select.component.html',
  styleUrls: ['./inverse-select.component.scss']
})
export class InverseSelectComponent implements OnInit {

  @Input() options: Option[];
  @Input() selected: string[];

  constructor(
    private _translateService: TranslateService
  ) { }

  ngOnInit() {
    console.log('inverseselect');
    console.log(this.options);
    console.log(this.selected);
  }

  translate(text: string): string {
    return this._translateService.instant('dict.' + text);
  }

  toggleClass(option: string): string {
    // console.log(this.selected); cos czesto tu wchodzi ?!
    var style = "info";
    if(this.selected.find(x => x == option)) return "label-" + style;
    return "label-inverse-" + style;
  }

  toggle(option: string): void {
    var index = this.selected.indexOf(option);
    if(index == -1) {
      this.selected.push(option);
    } else {
      var max = this.selected.length;
      var left = this.selected.slice(0, index);
      var right = this.selected.slice(index + 1, max);
      var newOptions = left.concat(right);
      for (let i = 0; i < max; i++) {
        this.selected.pop();
      }
      for (let i = 0; i < newOptions.length; i++) {
        this.selected.push(newOptions[i]);
      }
    }
  }

}
