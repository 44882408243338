import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialLoginComponent } from './social-login.component';
import {SocialLoginRoutingModule} from './social-login-routing.module';
import {SharedModule} from '../../../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    CommonModule,
    // SocialLoginRoutingModule,
    SharedModule,
    RouterModule
  ],
  declarations: [SocialLoginComponent]
})
export class SocialLoginModule { }
