import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '../local.storage.service';

@Injectable()
export class PremiumGuard implements CanActivate {

    constructor(
        private localStorage: LocalStorageService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('PremiumGuard');
        if (this.isPremiumUser()) {
            return true;
        } else {
            this.router.navigate(['/subscription']);
            return false;
        }
    }

    private isPremiumUser(): boolean {
        let user = this.localStorage.loadAuthenticatedUser();
        if (user == null) return false;
        return user.isPremium == "1";
    }
}