<div class="page-body">
  <div class="container-fluid">
    <form class="form-material" [formGroup]="form" (ngSubmit)="updateProfile()">
      <div class="row">
        <div class="col-sm-12">
          <!-- List view card start -->
          <div class="card" style="margin-top: 50px !important">
            <div class="card-header">
              <h5>{{'user.profile.title' | translate}}</h5>
            </div>
            <div class="row card-block">
              <div class="col-sm-12">
                <div class="material-group">
                  <div class="material-addone">
                    <i class="icofont icofont-email"></i>
                  </div>
                  <div class="form-group form-static-label" [ngClass]="getClassForRequiredFormField('email')">
                    <input type="text" class="form-control" id="iProfileEmail" placeholder="{{'user.profile.email' | translate}}" formControlName="email"
                      required readonly disabled>
                    <span class="form-bar"></span>
                    <label class="float-label" for="iProfileEmail">{{'user.profile.email' | translate}}</label>
                    <div class="messages text-danger" *ngIf="isRequiredFieldMissing('email')">{{'user.profile.form_validation.field_required' | translate}}</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="material-group">
                  <div class="material-addone">
                    <i class="icofont icofont-user"></i>
                  </div>
                  <div class="form-group form-static-label" [ngClass]="getClassForRequiredFormField('name')">
                    <input type="text" class="form-control" id="iProfileName" placeholder="{{'user.profile.fullname' | translate}}" formControlName="name"
                      required>
                    <span class="form-bar"></span>
                    <label class="float-label" for="iProfileName">{{'user.profile.fullname' | translate}}</label>
                    <div class="messages text-danger" *ngIf="isRequiredFieldMissing('name')">{{'user.profile.form_validation.field_required' | translate}}</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="material-group">
                  <div class="material-addone">
                    <i class="icofont icofont-mobile-phone"></i>
                  </div>
                  <div class="form-group form-static-label" [ngClass]="getClassForRequiredFormField('phone')">
                    <input type="text" class="form-control" id="iProfilePhone" placeholder="{{'user.profile.phone_number' | translate}}" formControlName="phone"
                      required>
                    <span class="form-bar"></span>
                    <label class="float-label" for="iProfilePhone">{{'user.profile.phone_number' | translate}}</label>
                    <div class="messages text-danger" *ngIf="isRequiredFieldMissing('phone')">{{'user.profile.form_validation.field_required' | translate}}</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="material-group">
                  <div class="material-addone">
                    <i class="icofont icofont-star"></i>
                  </div>
                  <div class="form-group form-static-label" [ngClass]="getClassForRequiredFormField('documents')">
                    <app-inverse-select class="form-control" style="border-bottom: 0px" id="iProfileDocuments" [options]="dictDriverDocuments" [selected]="selectedDriverDocuments"></app-inverse-select>
                    <span class="form-bar"></span>
                    <label class="float-label" for="iProfileDocuments">{{'user.profile.driver_documents' | translate}}</label>
                    <div class="messages text-danger" *ngIf="isRequiredFieldMissing('documents')">{{'user.profile.form_validation.field_required' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <!-- List view card start -->
          <div class="card">
            <div class="card-header">
              <button type="submit" [disabled]="!form.valid" class="btn btn-primary waves-effect waves-light m-r-20">{{'user.profile.button_save' | translate}}</button>
              &nbsp;
              <a class="btn btn-success" [routerLink]="['/subscription']">{{'user.profile.link_to_subscription_text' | translate}}</a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>