import { Component, OnInit, NgZone } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { UsersService } from 'src/app/services/users.service';
import { UpdateUserProfileDto, AuthenticatedUserDto, UserProfile, DICT_DRIVER_DOCUMENTS, Option, Dictionaries } from '../../../services/model';
import { ToastsService } from 'src/app/services/toasts.service';
import { TranslateService } from '@ngx-translate/core';
import { LogsService } from '../../../services/logs.service';
import { MapsAPILoader } from '@agm/core';
import { GeocodeService } from 'src/app/services/geocode.service';
import { Location } from 'src/app/services/model';
import { IOption } from 'ng-select';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

// export class UserProfile {
//   name: string;
//   position: string;
//   office: string;
//   age: number;
//   date: any;
//   salary: string;
// }

// declare var google: any;

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: [
    './user-profile.component.scss',
    '../../../../assets/icon/icofont/css/icofont.scss'
  ],
  animations: [
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('400ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class UserProfileComponent implements OnInit {

  dictDriverDocuments: Option[] = DICT_DRIVER_DOCUMENTS.sort((a, b) => (a.name > b.name) ? 1 : -1);;
  selectedDriverDocuments: string[] = [];

  public form: FormGroup;

  // private authenticatedUser: AuthenticatedUserDto;

  public userProfileEmail: string;

  public userProfileName: string;
  public newUserProfileName: string;

  public userProfilePhone: string;
  public newUserProfilePhone: string;

  // public userProfileAddress: string;
  // public newUserProfileAddress: string;

  // public userProfileLocation: string;
  // public newUserProfileLocation: string;

  // public userAvailabilityStart: string;
  // public newUserAvailabilityStart: string;

  // public userAvailabilityStop: string;
  // public newUserAvailabilityStop: string;

  public userDriverDocuments: string;

  public simpleOption: Array<IOption> = this.getDictDocuments();
  selectedDocumentIds: string[] = [];

  private isSubmitted: boolean = false;

  // lat = null;
  // lng = null;
  // zoom = 6;

  editProfile = true;
  editProfileIcon = 'icofont-edit';

  editAbout = true;
  editAboutIcon = 'icofont-edit';

  public editor;
  public editorContent: string;
  public editorConfig = {
    placeholder: 'About Your Self'
  };

  // public data: Observable<UserProfile>;
  public rowsOnPage = 10;
  public filterQuery = '';
  public sortBy = '';
  public sortOrder = 'desc';
  profitChartOption: any;

  constructor(
    public httpClient: HttpClient,
    // private localStorage: LocalStorageService,
    private usersService: UsersService,
    private toastsService: ToastsService,
    private translateService: TranslateService,
    // private geocodeService: GeocodeService,
    // private ngZone: NgZone,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.createForm();
    this.fetchUserProfile();

    // let user = this.localStorage.loadAuthenticatedUser();
    // if (user) {
    //   this.authenticatedUser = user;
    //   this.userProfileName = user.name;
    //   this.newUserProfileName = user.name;
    //   this.userProfileEmail = user.email !== 'undefined' ? user.email : null;
    //   // this.userProfilePhone = user.phoneNumber !== 'undefined' ? user.phoneNumber : null;
    // }

    setTimeout(() => {
      this.editorContent = 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising ';
      this.editorContent += 'pain was born and I will give you a complete account of the system, and expound the actual ';
      this.editorContent += 'teachings of the great explorer of the truth, the master-builder of human happiness. No one ';
      this.editorContent += 'rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who ';
      this.editorContent += 'do not know how to pursue pleasure rationally encounter consequences that are extremely ';
      this.editorContent += 'painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, ';
      this.editorContent += 'because it is pain, but because occasionally circumstances occur in which toil and pain can ';
      this.editorContent += 'procure him some great pleasure. To take a trivial example, which of us ever undertakes ';
      this.editorContent += 'laborious physical exercise, except to obtain some advantage from it? But who has any right ';
      this.editorContent += 'to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, ';
      this.editorContent += 'or one who avoids a pain that produces no resultant pleasure? On the other hand, we denounce ';
      this.editorContent += 'with righteous indignation and dislike men who are so beguiled and demoralized by the charms ';
      this.editorContent += 'of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and ';
      this.editorContent += 'trouble that are bound to ensue; and equal blame belongs to those who fail in their duty ';
      this.editorContent += 'through weakness of will, which is the same as saying through shrinking from toil and pain. ';
      this.editorContent += 'These cases are perfectly simple and easy to distinguish. In a free hour, when our power of ';
      this.editorContent += 'choice is untrammelled and when nothing prevents our being able To Do what we like best, ';
      this.editorContent += 'every pleasure is to be welcomed and every pain avoided. But in certain circumstances and ';
      this.editorContent += 'owing to the claims of duty or the obligations of business it will frequently occur that ';
      this.editorContent += 'pleasures have to be repudiated and annoyances accepted. The wise man therefore always holds';
      this.editorContent += 'in these matters to this principle of selection: he rejects pleasures to secure other ';
      this.editorContent += 'greater pleasures, or else he endures pains to avoid worse pain.';
    }, 2800);

    // this.data = this.httpClient.get<UserProfile>(`assets/data/data.json`);

    setTimeout(() => {
      this.profitChartOption = {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            const date = new Date(params.value[0]);
            let data = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ';
            data += date.getHours() + ':' + date.getMinutes();
            return data + '<br/>' + params.value[1] + ', ' + params.value[2];
          },
          responsive: true
        },
        dataZoom: {
          show: true,
          start: 70
        },
        legend: {
          data: ['Profit']
        },
        grid: {
          y2: 80
        },
        xAxis: [{
          type: 'time',
          splitNumber: 10
        }],
        yAxis: [{
          type: 'value'
        }],
        series: [{
          name: 'Profit',
          type: 'line',
          showAllSymbol: true,
          symbolSize: function (value) {
            return Math.round(value[2] / 10) + 2;
          },
          data: (function () {
            const d: any = [];
            let len = 0;
            const now = new Date();
            while (len++ < 200) {
              const random1: any = (Math.random() * 30).toFixed(2);
              const random2: any = (Math.random() * 100).toFixed(2);
              d.push([new Date(2014, 9, 1, 0, len * 10000), random1 - 0, random2 - 0]);
            }
            return d;
          })()
        }]
      };
    }, 1);
  }

  private fetchUserProfile(): void {
    this.usersService.getProfile().subscribe(result => {
      this.setLocalProfile(result.data);
    }, error => {
      this.toastsService.error(this.translateService.instant('user.profile.fetching_error'));
    });
  }

  private createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: [''],
      documents: [],
      // address: [],
      // start: [],
      // stop: []
    });
    // register form on changes
    // this.form.get('address').valueChanges.pipe(debounceTime(1000)).subscribe(val => {
    //   this.addressUpdated(val);
    // });
  }

  private setLocalProfile(profile: UserProfile): void {
    this.userProfileEmail = profile.userEmail;

    this.userProfileName = profile.userName;
    this.newUserProfileName = profile.userName;

    // this.userProfileAddress = profile.userAddress;
    // this.newUserProfileAddress = profile.userAddress;

    // this.userProfileLocation = profile.userLocation;
    // this.newUserProfileLocation = profile.userLocation;

    // if (this.userProfileLocation && this.userProfileLocation.length > 0 && this.userProfileLocation.indexOf('|') > 0) {
    //   let coordinates = this.userProfileLocation.split('|');
    //   this.lat = +coordinates[0];
    //   this.lng = +coordinates[1];
    // }

    // this.userAvailabilityStart = profile.userAvailabilityStart;
    // this.newUserAvailabilityStart = profile.userAvailabilityStart;

    // this.userAvailabilityStop = profile.userAvailabilityStop;
    // this.newUserAvailabilityStop = profile.userAvailabilityStop;

    this.userProfilePhone = profile.userPhone;
    this.newUserProfilePhone = profile.userPhone;

    this.selectedDocumentIds = [];
    profile.driverDocuments.forEach(dd => {
      this.selectedDocumentIds.push('' + Dictionaries.DictDriverDocumentId(dd));
    });
    this.userDriverDocuments = profile.driverDocuments.map(x => this.translateService.instant('dict.' + x)).join(', ');

    this.form.get('name').setValue(profile.userName);
    this.form.get('email').setValue(profile.userEmail);
    this.form.get('phone').setValue(profile.userPhone);
    // this.form.get('address').setValue(profile.userAddress);
    // this.form.get('start').setValue(profile.userAvailabilityStart);
    // this.form.get('stop').setValue(profile.userAvailabilityStop);

    this.selectedDriverDocuments = profile.driverDocuments;
  }

  toggleEditProfile() {
    this.editProfileIcon = (this.editProfileIcon === 'icofont-close') ? 'icofont-edit' : 'icofont-close';
    this.editProfile = !this.editProfile;
  }

  toggleEditAbout() {
    this.editAboutIcon = (this.editAboutIcon === 'icofont-close') ? 'icofont-edit' : 'icofont-close';
    this.editAbout = !this.editAbout;
  }

  onEditorBlured(quill) {
    // console.log('editor blur!', quill);
  }

  onEditorFocused(quill) {
    // console.log('editor focus!', quill);
  }

  onEditorCreated(quill) {
    this.editor = quill;
    // console.log('quill is ready! this is current quill instance object', quill);
  }

  onContentChanged({ quill, html, text }) {
    // console.log('quill content is changed!', quill, html, text);
  }

  private isProfileDataValid(): boolean {
    // return this.newUserProfileName.length > 0;
    return this.form.valid;
  }

  private convertFormToDto(): UpdateUserProfileDto {
    return new UpdateUserProfileDto(
      this.form.get('name').value,
      // this.form.get('address').value,
      // this.newUserProfileLocation,
      // this.form.get('start').value,
      // this.form.get('stop').value,
      this.form.get('phone').value,
      // this.selectedDocumentIds.map(x => Dictionaries.DictDriverDocumentName(+x))
      this.selectedDriverDocuments
    );
  }

  // private findLocationOnMap(address: string): void {
  //   this.geocodeService.geocodeAddress(address)
  //     .subscribe((location: Location) => {
  //       this.ngZone.run(() => {
  //         this.lat = location.lat;
  //         this.lng = location.lng;
  //         this.newUserProfileLocation = location.lat + '|' + location.lng;
  //       });
  //     });
  // }

  // public addressUpdated(newAddress: string): void {
  //   this.findLocationOnMap(newAddress);
  // }

  public updateProfile(): void {
    if (this.isSubmitted == false) {
      if (this.isProfileDataValid()) {
        let dto = this.convertFormToDto();
        this.isSubmitted = true;
        this.usersService.updateProfile(dto).subscribe(res => {
          this.toggleEditProfile();
          this.toastsService.success(this.translateService.instant('user.profile.update_profile_success'));

          // this.authenticatedUser.email = res.data.userEmail;
          // this.authenticatedUser.name = res.data.userName;
          // this.localStorage.storeAuthenticatedUser(this.authenticatedUser);

          // this.userProfileEmail = res.data.userEmail;
          // this.userProfileName = res.data.userName;
          // this.newUserProfileName = res.data.userName;
          this.setLocalProfile(res.data);

          this.isSubmitted = false;
        }, e => {
          LogsService.Error(e);
          let errorType = 'undefined';
          if(e.error) {
            errorType = e.error.ErrorType;
          }
          this.toastsService.error(this.translateService.instant('error_types.' + errorType), this.translateService.instant('user.profile.update_profile_error'));
          // if (e.error) {
          //   this.toastsService.error(Object.values(e.error).join('<br/>'), this.translateService.instant('user.profile.update_profile_error'));
          // } else {
          //   this.toastsService.error(this.translateService.instant('user.profile.update_profile_error'));
          // }
          this.isSubmitted = false;
        })

      }

    }
  }

  private getDictDocuments(): Array<IOption> {
    return this.cloneOptions(DICT_DRIVER_DOCUMENTS);
  }

  private cloneOptions(options: Array<Option>): Array<IOption> {
    return options.map(option => ({ value: '' + option.id, label: 'dict.' + option.name }));
  }

  getClassForRequiredFormField(fieldName: string): string {
    let control = this.form.get(fieldName);
    if (this.isControlInvalid(control)) return 'has-error';
    if (this.isControlValid(control)) return 'has-success';
    return '';
  }

  private isControlValid(control: AbstractControl): boolean {
    return control.touched && control.valid;
  }

  private isControlInvalid(control: AbstractControl): boolean {
    return control.touched && !control.valid;
  }

  isRequiredFieldMissing(fieldName: string): boolean {
    return this.form.get(fieldName).touched &&
      this.form.get(fieldName).errors &&
      this.form.get(fieldName).errors.required;
  }

}
