export const environment = {
  name: 'STG',
  production: false,
  apiUrl: 'https://api.skoczek.creyn.pl/',
  FACEBOOK_LOGIN_CLIENT_ID: '2364034947192562',
  headerTheme: 'theme2',
  freamType: 'theme3',
  layoutType: 'light',
  paymentGatewaytUrl: 'https://secure.tpay.com',
  paymentDescription: 'Jumper Driver TEST 1M',
  paymentCallbackUrl: 'https://api.skoczek.creyn.pl/api/subscription/payment',
  paymentReturnUrl: 'https://company.skoczek.creyn.pl/subscription/payment/success',
  paymentReturnErrorUrl: 'https://company.skoczek.creyn.pl/subscription/payment/failure'
};
