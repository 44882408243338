import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/services/local.storage.service';

@Injectable()
export class ClientIdInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        request = request.clone({
            setHeaders: {
                'x-jd-driver': '1fb871a2-9bd4-4988-98a9-9b2a3be9813d'
            }
        });

        return next.handle(request);
    }
}