import { Component, OnInit } from '@angular/core';
import { BrowseCourseViewModel, CourseStatus, AvailableActions } from '../courses.model';
import { CoursesService } from 'src/app/services/courses.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastsService } from 'src/app/services/toasts.service';
import { CourseDto } from '../../services/model';
import { LogsService } from '../../services/logs.service';
import { PushSubscriptionService } from 'src/app/services/push.subscription.service';

@Component({
  selector: 'app-browse-courses',
  templateUrl: './browse-courses.component.html',
  styleUrls: ['./browse-courses.component.scss']
})
export class BrowseCoursesComponent implements OnInit {

  constructor(
    private coursesService: CoursesService,
    private translateService: TranslateService,
    private toastsService: ToastsService,
    private logs: LogsService,
    private pushService: PushSubscriptionService
  ) { }

  public submitted: boolean;
  loadCard: boolean;
  allCourses: BrowseCourseViewModel[];

  ngOnInit() {
    this.submitted = false;
    this.pushService.AskUserForPushSubscriptionIfNeeded();
    this.loadCard = true;
    this.coursesService.getCoursesFromApi().subscribe(res => {
      // this.logs.Log(res);
      this.allCourses = res.data.map<BrowseCourseViewModel>(this.mapDtoToViewModel.bind(this));
      this.loadCard = false;
    }, error => {
      this.loadCard = false;
    });
  }

  private mapDtoToViewModel(d: CourseDto): BrowseCourseViewModel {
    return <BrowseCourseViewModel>{
      courseId: d.id,
      arrivalCity: d.arrivalCity,
      arrivalCountry: d.arrivalCountry,
      cargoType: this.mapCargoType(d.cargoType),
      truckType: this.mapTruckType(d.truckType),
      departureCity: d.departureCity,
      departureCountry: d.departureCountry,
      distance: d.calculatedDistance ? d.calculatedDistance : '-- km',
      driverDocuments: this.mapDriverDocuments(d.driverDocuments),
      price: d.priceValue + ' ' + d.priceCurrency,
      startDate: this.convertServerTimeToLocal(d.startDate),
      phoneNumber: d.phoneNumber,
      status: this.mapStatus(d.status),
      statusText: this.mapStatusText(d.status),
      isEditable: d.isEditable,
      actions: {
        canEdit: d.actions.canEdit,
        canRegister: d.actions.canRegister,
        canUnregister: d.actions.canUnregister
      }
    }
  }



  private convertServerTimeToLocal(datetime: string): string {
    return datetime.substr(0, 10);
  }

  private mapDriverDocuments(fromServer: string[]): string[] {
    return fromServer.map(x => this.translateService.instant('dict.' + x));
  }

  private mapCargoType(fromServer: string): string {
    return this.translateService.instant('dict.' + fromServer);
  }

  private mapTruckType(fromServer: string): string {
    return this.translateService.instant('dict.' + fromServer);
  }

  private mapStatusText(fromServer: string): string {
    return this.translateService.instant('dict.' + fromServer);
  }

  private mapStatus(fromServer: string): CourseStatus {
    if (fromServer == 'DS_NEW') return CourseStatus.New;
    if (fromServer == 'DS_REGISTERED') return CourseStatus.Registered;
    if (fromServer == 'DS_ONGOING') return CourseStatus.Ongoing;
    if (fromServer == 'DS_EXPIRED') return CourseStatus.Expired;
    if (fromServer == 'DS_EXECUTED') return CourseStatus.Executed;
    if (fromServer == 'DS_FINISHED') return CourseStatus.Finished;
    this.logs.Error('Unknown course status: [' + fromServer + ']');
    return CourseStatus.Unknown;
  }

  public registerForCourse(courseId: string) {
    if (!this.submitted) {
      this.submitted = true;
      this.coursesService.registerForCourse(courseId).subscribe(res => {
        this.updateCourse(courseId, res.data);
        this.toastsService.success(this.translateService.instant('courses.register.success'));
        this.submitted = false;
      }, e => {
        this.logs.Error(e);
        if (e.status == 400) {
          this.toastsService.error(
            this.translateService.instant('error_types.' + e.error.ErrorType + '.' + e.error.Message),
            this.translateService.instant('courses.register.error_title')
          );
        }
        else {
          this.toastsService.error(this.translateService.instant('courses.register.error_title'));
        }
        this.submitted = false;
      });
    }
  }

  public unregisterFromCourse(courseId: string) {
    if (!this.submitted) {
      this.submitted = true;
      this.coursesService.unregisterFromCourse(courseId).subscribe(res => {
        this.updateCourse(courseId, res.data);
        this.toastsService.success(this.translateService.instant('courses.unregister.success'));
        this.submitted = false;
      }, e => {
        this.logs.Error(e);
        if (e.status == 400) {
          this.toastsService.error(
            this.translateService.instant('error_types.' + e.error.ErrorType + '.' + e.error.Message),
            this.translateService.instant('courses.unregister.error_title')
          );
        }
        else {
          this.toastsService.error(this.translateService.instant('courses.unregister.error_title'));
        }
        this.submitted = false;
      });
    }
  }

  private updateCourse(courseId: string, newCourse: CourseDto) {
    let i = this.allCourses.findIndex(x => x.courseId == courseId);
    this.allCourses[i] = this.mapDtoToViewModel(newCourse);
  }

  public canRegister(course: BrowseCourseViewModel): boolean {
    return course.actions.canRegister;
  }

  public canUnregister(course: BrowseCourseViewModel): boolean {
    return course.actions.canUnregister;
  }

  public isRegistered(status: CourseStatus): boolean {
    return status == CourseStatus.Registered;
  }

  public call(phoneNumber: string) {
    // window.open('tel:' + phoneNumber);
    document.getElementById('call_' + phoneNumber).click();
  }

  anyCourses(): boolean {
    return this.allCourses && this.allCourses.length > 0;
  }

  cardClass(course: BrowseCourseViewModel): string {
    if (course.actions.canUnregister) return "bg-success";
  }
}
