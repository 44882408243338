<div class="page-body">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <!-- List view card start -->
        <div class="card" style="margin-top: 50px !important">
          <div class="card-header">
            <h5>{{'courses.browse.text_list_of_courses' | translate}}</h5>

          </div>
          <div class="row card-block">
            <div class="col-md-12">
              <label *ngIf="!anyCourses()">{{'courses.browse.text_no_courses' | translate}}</label>
              <div class="card-loader" *ngIf="loadCard"><i class="fa fa-spinner rotate-refresh"></i></div>
              <ul class="list-view">
                <li *ngFor="let c of allCourses">
                  <div class="card list-view-media" [ngClass]="cardClass(c)">
                    <div class="card-block">
                      <div class="media">
                        <!-- <a class="media-left ripple light" href="javascript:">
                        <img class="media-object card-list-img" src="assets/images/avatar-1.jpg" alt="Generic placeholder image">
                      </a> -->
                        <div class="media-body">
                          <div class="col-xs-12">
                            <h6 class="d-inline-block">
                              {{c.departureCity}} ({{c.departureCountry}}) > {{c.arrivalCity}} ({{c.arrivalCountry}})</h6>
                            <!-- <label class="label label-info">{{c.headingInfo}}</label> -->
                          </div>
                          <div class="f-13 text-muted m-b-15">
                            {{c.startDate}} | {{c.distance}} |
                            <label class="label label-inverse-warning">{{c.price}}</label>
                            <label class="label label-inverse-info">{{c.statusText}}</label>
                          </div>
                          <p>{{'courses.browse.text_cargo_type' | translate}} :
                            <b>{{c.cargoType}}</b>
                          </p>
                          <p>{{'courses.browse.text_truck_type' | translate}} :
                            <b>{{c.truckType}}</b>
                          </p>
                          <p>{{'courses.browse.text_driver_documents' | translate}} :
                            <b>{{c.driverDocuments.join(' | ')}}</b>
                          </p>
                          <div class="m-t-15">
                            <!-- <button type="button" data-toggle="tooltip" title="Zobacz na mapie" class="btn btn-info btn-mini ripple light">
                            <span class="icofont">Zobacz na mapie</span>
                          </button> -->
                            <button *ngIf="canRegister(c)" type="button" (click)="registerForCourse(c.courseId)" data-toggle="tooltip" class="btn btn-success btn-mini ripple light">
                              <span class="icofont">{{'courses.browse.text_action_register_for_course' | translate}}</span>
                            </button>
                            <button *ngIf="canUnregister(c)" type="button" (click)="unregisterFromCourse(c.courseId)" data-toggle="tooltip"
                              class="btn btn-danger btn-mini ripple light">
                              <span class="icofont">{{'courses.browse.text_action_unregister_from_course' | translate}}</span>
                            </button>
                            <button type="button" (click)="call(c.courseId)" data-toggle="tooltip" class="btn btn-info btn-mini ripple light">
                              <span class="icofont">{{'courses.browse.text_action_call' | translate}} : {{c.phoneNumber}}</span>
                            </button>
                            <a id="call_{{c.courseId}}" href="tel:{{c.phoneNumber}}"></a>
                            <!-- <button type="button" data-toggle="tooltip" title="Linkedin" class="btn btn-linkedin btn-mini ripple light">
                            <span class="icofont icofont-brand-linkedin"></span>
                          </button>
                          <button type="button" data-toggle="tooltip" title="Drible" class="btn btn-dribbble btn-mini ripple light">
                            <span class="icofont icofont-social-dribble"></span>
                          </button> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- List view card end -->
      </div>
    </div>
  </div>
</div>