import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RegisterNewUserDto, LoginUserDto, AuthenticatedUserDto, ApiResponse, ResetPasswordDto, LoginWithSocialDto, UpdateUserProfileDto, UserProfile, UserAvailability, UpdateUserAvailabilityDto } from './model';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class UsersService {

    constructor(
        private http: HttpClient,
        // private localStorage: LocalStorageService,
        // private router: Router
    ) {
        this.apiBaseUrl = environment.apiUrl;
    }

    private apiBaseUrl: string;

    registerNewUser(dto: RegisterNewUserDto): Observable<ApiResponse<boolean>> {
        return this.http.post<ApiResponse<boolean>>(this.apiBaseUrl + 'api/users/register', dto);
    }

    loginUser(dto: LoginUserDto): Observable<ApiResponse<AuthenticatedUserDto>> {
        return this.http.post<ApiResponse<AuthenticatedUserDto>>(this.apiBaseUrl + 'api/users/login', dto);
    }

    loginFacebookUser(token: string): Observable<ApiResponse<AuthenticatedUserDto>> {
        let dto = new LoginWithSocialDto(token);
        return this.http.post<ApiResponse<AuthenticatedUserDto>>(this.apiBaseUrl + 'api/users/fblogin', dto);
    }

    resetPassword(dto: ResetPasswordDto): Observable<ApiResponse<boolean>> {
        return this.http.post<ApiResponse<boolean>>(this.apiBaseUrl + 'api/users/reset', dto);
    }

    resetPasswordConfirm(email: string, token: string, password: string): Observable<ApiResponse<boolean>> {
        return this.http.get<ApiResponse<boolean>>(this.apiBaseUrl + 'api/users/reset?email=' + email + '&token=' + token + '&newPassword=' + password);
    }

    activateUser(email: string, token: string): Observable<ApiResponse<boolean>> {
        return this.http.get<ApiResponse<boolean>>(this.apiBaseUrl + 'api/users/activate?email=' + email + '&token=' + token);
    }

    sendSubscriptionToServer(subscription: PushSubscription): Observable<ApiResponse<boolean>> {
        return this.http.post<ApiResponse<boolean>>(this.apiBaseUrl + 'api/users/subscriptions', subscription);
    }

    getProfile(): Observable<ApiResponse<UserProfile>> {
        return this.http.get<ApiResponse<UserProfile>>(this.apiBaseUrl + 'api/users/profile');
    }

    updateProfile(profile: UpdateUserProfileDto): Observable<ApiResponse<UserProfile>> {
        return this.http.post<ApiResponse<UserProfile>>(this.apiBaseUrl + 'api/users/profile', profile);
    }

    getAvailability(): Observable<ApiResponse<UserAvailability>> {
        return this.http.get<ApiResponse<UserAvailability>>(this.apiBaseUrl + 'api/users/profileavailability');
    }

    updateAvailability(availability: UpdateUserAvailabilityDto): Observable<ApiResponse<UserAvailability>> {
        return this.http.post<ApiResponse<UserAvailability>>(this.apiBaseUrl + 'api/users/profileavailability', availability);
    }
}