import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastsService } from 'src/app/services/toasts.service';
import { UsersService } from 'src/app/services/users.service';
import { LoginUserDto } from 'src/app/services/model';
import { EmailValidator } from 'src/app/services/validators/email.validator';
import { LocalStorageService } from '../../../../services/local.storage.service';
import { LogsService } from 'src/app/services/logs.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss']
})
export class SocialLoginComponent implements OnInit {

  constructor(
    @Inject('BASE_URL') private baseUrl: string,
    private router: Router,
    private translateService: TranslateService,
    private toastsService: ToastsService,
    private userService: UsersService,
    private fb: FormBuilder,
    private localStorageService: LocalStorageService,
    private logs: LogsService
  ) {
    if (window.addEventListener) {
      window.removeEventListener("message", this.handleMessage.bind(this), false);
      window.addEventListener("message", this.handleMessage.bind(this), false);
    } else {
      (<any>window).attachEvent("onmessage", this.handleMessage.bind(this));
    }
  }

  private authWindow: Window;
  public form: FormGroup;
  public submitted: boolean;

  ngOnInit() {
    document.querySelector('body').setAttribute('themebg-pattern', 'theme1');

    this.submitted = false;
    this.form = this.fb.group({
      'email': ['', Validators.compose([Validators.required, EmailValidator.validate, Validators.minLength(4)])],
      'password': ['', [Validators.required]],
    });
  }

  login() {
    if (!this.submitted && this.form.valid) {

      let email = this.form.get('email').value;
      let password = this.form.get('password').value;

      var dto = new LoginUserDto(email, password);

      this.logs.Info('Login for:' + email);
      this.submitted = true;
      this.userService.loginUser(dto).subscribe(res => {
        // this.logs.Log("> OK");
        // this.logs.Warn(res);
        this.toastsService.success(this.translateService.instant('auth.login.success'));
        this.localStorageService.storeAuthenticatedUser(res.data);
        this.router.navigateByUrl('/courses');
        this.submitted = false;
      }, e => {
        // this.logs.Log("> NOK");
        this.logs.Error(e);
        this.toastsService.error(this.translateService.instant('auth.login.error_title'));
        this.submitted = false;
      });
    }
  }

  launchFbLogin() {
    let appId = environment.FACEBOOK_LOGIN_CLIENT_ID;
    let redirectUri = this.baseUrl + '/facebookauth.html';
    let fbUri = 'https://www.facebook.com/v3.2/dialog/oauth?' +
      'response_type=token' +
      '&display=popup' +
      '&client_id=' + appId +
      '&display=popup' +
      '&redirect_uri=' + redirectUri +
      '& scope=email';
    this.authWindow = window.open(fbUri, null, 'width=600,height=400');
  }

  handleMessage(event: Event) {
    // this.logs.Warn('>>>>>>>>>');
    this.logs.Info(event);
    const message = event as MessageEvent;
    // this.logs.Warn(message);
    // Only trust messages from the below origin.
    // this.logs.Warn(this.baseUrl);
    // alert(message);
    if ((message.origin + '/') !== this.baseUrl) return;

    let result = JSON.parse(message.data);
    // this.logs.Info(result);

    if (result.type && result.type == 'sociallogin') {

      this.authWindow.close();

      if (!result.status) {
        this.toastsService.error(this.translateService.instant('auth.login.error_title'));
      }
      else {
        this.userService.loginFacebookUser(result.accessToken)
          .subscribe(res => {
            this.toastsService.success(this.translateService.instant('auth.login.success'));
            this.localStorageService.storeAuthenticatedUser(res.data);
            this.router.navigateByUrl('/courses');
          },
            error => {
              this.toastsService.error(this.translateService.instant('auth.login.error_title'));
            }
          );
      }
    }
  }
}
