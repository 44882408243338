<div class="page-body">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6 offset-sm-3">
        <div class="card" style="margin-top: 50px !important">
          <div class="card-header">
            <h5>{{'premium.payment.title' | translate}}</h5>
          </div>
          <div class="card-footer">
            <div class="media">
              <div class="row">
                <div class="col-sm-12">
                  <label>{{'premium.payment.success' | translate}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>