import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LogsService } from './app/services/logs.service';

if (environment.production) {
  enableProdMode();
}

export function getBaseUrl() {
  // LogsService.Log('base:');
  LogsService.Log('base:' + document.getElementsByTagName('base'));
  return document.getElementsByTagName('base')[0].href;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .then(() => {
    if('serviceWorker' in navigator) {
      // https://github.com/angular/angular-cli/issues/13351
      navigator.serviceWorker.register('./ngsw-worker.js');
    }
  })
  .catch(err => LogsService.Error(err));
