/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inverse-select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./inverse-select.component";
import * as i4 from "@ngx-translate/core";
var styles_InverseSelectComponent = [i0.styles];
var RenderType_InverseSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InverseSelectComponent, data: {} });
export { RenderType_InverseSelectComponent as RenderType_InverseSelectComponent };
function View_InverseSelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [["class", "label"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle(_v.context.$implicit.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "label"; var currVal_1 = _co.toggleClass(_v.context.$implicit.name); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.translate(_v.context.$implicit.name); _ck(_v, 3, 0, currVal_2); }); }
export function View_InverseSelectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"], ["style", "display:block"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InverseSelectComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_InverseSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inverse-select", [], null, null, null, View_InverseSelectComponent_0, RenderType_InverseSelectComponent)), i1.ɵdid(1, 114688, null, 0, i3.InverseSelectComponent, [i4.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InverseSelectComponentNgFactory = i1.ɵccf("app-inverse-select", i3.InverseSelectComponent, View_InverseSelectComponent_Host_0, { options: "options", selected: "selected" }, {}, []);
export { InverseSelectComponentNgFactory as InverseSelectComponentNgFactory };
