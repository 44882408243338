export class BrowseCourseViewModel {
    courseId: string
    departureCountry: string;
    departureCity: string;
    arrivalCountry: string;
    arrivalCity: string;
    startDate: string;
    distance: string;
    price: string;
    cargoType: string;
    truckType: string;
    driverDocuments: string[];
    phoneNumber: string;
    status: CourseStatus;
    statusText: string;
    isEditable: boolean;
    actions: AvailableActions;
}

export enum CourseStatus {
    Unknown,
    New,
    Registered,
    Ongoing,
    Expired,
    Executed,
    Finished
}

export class AvailableActions {
    canEdit: boolean;
    canRegister: boolean;
    canUnregister: boolean;
}