import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { UsersService } from 'src/app/services/users.service';
import { ToastsService } from 'src/app/services/toasts.service';
import { TranslateService } from '@ngx-translate/core';
import { UserAvailability, UpdateUserAvailabilityDto, DICT_DRIVER_DOCUMENTS, Option, Dictionaries } from 'src/app/services/model';
import { GeocodeService } from 'src/app/services/geocode.service';
import { Location } from 'src/app/services/model';
import { LogsService } from 'src/app/services/logs.service';
import { MapsAPILoader } from '@agm/core';
import { debounceTime } from 'rxjs/operators';
import { IOption } from 'ng-select';

declare var google: any;

@Component({
  selector: 'app-user-availability',
  templateUrl: './user-availability.component.html',
  styleUrls: [
    './user-availability.component.scss',
    '../../../../assets/icon/icofont/css/icofont.scss'
  ]
})
export class UserAvailabilityComponent implements OnInit {

  public form: FormGroup;

  dictDriverDocuments: Option[] = DICT_DRIVER_DOCUMENTS.sort((a, b) => (a.name > b.name) ? 1 : -1);;
  selectedDriverDocuments: string[] = [];

  public userProfileEmail: string;

  public userProfileName: string;
  public newUserProfileName: string;

  public userProfilePhone: string;
  public newUserProfilePhone: string;

  public userDriverDocuments: string;

  public simpleOption: Array<IOption> = this.getDictDocuments();
  selectedDocumentIds: string[] = [];

  public userProfileAddress: string;
  public newUserProfileAddress: string;

  public userProfileLocation: string;
  public newUserProfileLocation: string;

  public userAvailabilityStart: string;
  public newUserAvailabilityStart: string;

  public userAvailabilityStop: string;
  public newUserAvailabilityStop: string;

  private isSubmitted: boolean = false;

  
  lat = null;
  lng = null;
  zoom = 6;

  constructor(
    private fb: FormBuilder,
    private usersService: UsersService,
    private toastsService: ToastsService,
    private translateService: TranslateService,
    private geocodeService: GeocodeService,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    this.createForm();
    this.fetchUserAvailability();
  }

  private createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: [''],
      documents: [],
      address: ['', Validators.required],
      start: ['', Validators.required],
      stop: ['', Validators.required]
    });
    // register form on changes
    this.form.get('address').valueChanges.pipe(debounceTime(1000)).subscribe(val => {
      this.addressUpdated(val);
    });
  }

  private fetchUserAvailability(): void {
    this.usersService.getAvailability().subscribe(result => {
      this.setLocalAvailability(result.data);
    }, error => {
      this.toastsService.error(this.translateService.instant('user.profile.fetching_error'));
    });
  }

  private setLocalAvailability(dto: UserAvailability): void {
    this.userProfileEmail = dto.userEmail;
    
    this.userProfileName = dto.userName;
    this.newUserProfileName = dto.userName;

    this.userProfilePhone = dto.userPhone;
    this.newUserProfilePhone = dto.userPhone;

    this.selectedDocumentIds = [];
    dto.driverDocuments.forEach(dd => {
      this.selectedDocumentIds.push('' + Dictionaries.DictDriverDocumentId(dd));
    });
    this.userDriverDocuments = dto.driverDocuments.map(x => this.translateService.instant('dict.' + x)).join(', ');

    this.userProfileAddress = dto.userAddress;
    this.newUserProfileAddress = dto.userAddress;

    this.userProfileLocation = dto.userLocation;
    this.newUserProfileLocation = dto.userLocation;

    if (this.userProfileLocation && this.userProfileLocation.length > 0 && this.userProfileLocation.indexOf('|') > 0) {
      let coordinates = this.userProfileLocation.split('|');
      this.lat = +coordinates[0];
      this.lng = +coordinates[1];
    }

    this.userAvailabilityStart = dto.userAvailabilityStart;
    this.newUserAvailabilityStart = dto.userAvailabilityStart;

    this.userAvailabilityStop = dto.userAvailabilityStop;
    this.newUserAvailabilityStop = dto.userAvailabilityStop;

    this.form.get('name').setValue(dto.userName);
    this.form.get('email').setValue(dto.userEmail);
    this.form.get('phone').setValue(dto.userPhone);
    this.form.get('address').setValue(dto.userAddress);
    this.form.get('start').setValue(dto.userAvailabilityStart);
    this.form.get('stop').setValue(dto.userAvailabilityStop);

    this.selectedDriverDocuments = dto.driverDocuments;
  }

  private isFormDataValid(): boolean {
    // return this.newUserProfileName.length > 0;
    return this.form.valid;
  }

  private convertFormToDto(): UpdateUserAvailabilityDto {
    return new UpdateUserAvailabilityDto(
      this.form.get('name').value,
      this.form.get('address').value,
      this.newUserProfileLocation,
      this.form.get('start').value,
      this.form.get('stop').value,
      this.form.get('phone').value,
      // this.selectedDocumentIds.map(x => Dictionaries.DictDriverDocumentName(+x))
      this.selectedDriverDocuments
    );
  }


  private findLocationOnMap(address: string): void {
    this.geocodeService.geocodeAddress(address)
      .subscribe((location: Location) => {
        this.ngZone.run(() => {
          this.lat = location.lat;
          this.lng = location.lng;
          this.newUserProfileLocation = location.lat + '|' + location.lng;
        });
      });
  }

  public addressUpdated(newAddress: string): void {
    this.findLocationOnMap(newAddress);
  }

  public updateAvailability(): void {
    if (this.isSubmitted == false) {
      if (this.isFormDataValid()) {
        let dto = this.convertFormToDto();
        this.isSubmitted = true;
        this.usersService.updateAvailability(dto).subscribe(res => {
          // this.toggleEditProfile();
          this.toastsService.success(this.translateService.instant('user.profile.update_profile_success'));

          // this.authenticatedUser.email = res.data.userEmail;
          // this.authenticatedUser.name = res.data.userName;
          // this.localStorage.storeAuthenticatedUser(this.authenticatedUser);

          // this.userProfileEmail = res.data.userEmail;
          // this.userProfileName = res.data.userName;
          // this.newUserProfileName = res.data.userName;
          this.setLocalAvailability(res.data);

          this.isSubmitted = false;
        }, e => {
          LogsService.Error(e);
          let errorType = 'undefined';
          if(e.error) {
            errorType = e.error.ErrorType;
          }
          this.toastsService.error(this.translateService.instant('error_types.' + errorType), this.translateService.instant('user.profile.update_profile_error'));
          // if (e.error) {
          //   this.toastsService.error(Object.values(e.error).join('<br/>'), this.translateService.instant('user.profile.update_profile_error'));
          // } else {
          //   this.toastsService.error(this.translateService.instant('user.profile.update_profile_error'));
          // }
          this.isSubmitted = false;
        })

      }

    }
  }

  getClassForRequiredFormField(fieldName: string): string {
    let control = this.form.get(fieldName);
    if (this.isControlInvalid(control)) return 'has-error';
    if (this.isControlValid(control)) return 'has-success';
    return '';
  }

  private isControlValid(control: AbstractControl): boolean {
    return control.touched && control.valid;
  }

  private isControlInvalid(control: AbstractControl): boolean {
    return control.touched && !control.valid;
  }

  private getDictDocuments(): Array<IOption> {
    return this.cloneOptions(DICT_DRIVER_DOCUMENTS);
  }

  private cloneOptions(options: Array<Option>): Array<IOption> {
    return options.map(option => ({ value: '' + option.id, label: 'dict.' + option.name }));
  }

  isRequiredFieldMissing(fieldName: string): boolean {
    return this.form.get(fieldName).touched &&
      this.form.get(fieldName).errors &&
      this.form.get(fieldName).errors.required;
  }
}
