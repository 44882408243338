import { Component, OnInit } from '@angular/core';
import { SubscriptionViewModel, Subscription, ApiResponse, InvoiceData } from '../../services/model';
import { TPayPaymentLinkGenerator } from '../../services/tpay.payment.link.generator';
import { PremiumSubscriptionService } from '../../services/premium.subscription.service';
import { LogsService } from '../../services/logs.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastsService } from '../../services/toasts.service';
import { LocalStorageService } from 'src/app/services/local.storage.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  private futureSubscription: Subscription;
  private currentSubscription: SubscriptionViewModel;

  public allSubscriptions: SubscriptionViewModel[] = [];
  public loadCard: boolean;

  public invoiceNeeded: boolean = false;
  public form: FormGroup;
  private submitted: boolean = false;

  constructor(
    private premium: PremiumSubscriptionService,
    private paymentLinkGenerator: TPayPaymentLinkGenerator,
    private storage: LocalStorageService,
    private fb: FormBuilder,
    private toastsService: ToastsService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.checkFutureSubscriptionFirst();

    this.form = this.fb.group({
      'nip': ['', [Validators.required]],
      'name': ['', [Validators.required]],
      'address': ['', [Validators.required]],
      'zip': ['', [Validators.required]],
      'city': ['', [Validators.required]],
    });
  }

  private checkFutureSubscriptionFirst() {
    this.premium.getFutureSubscription().subscribe(response => {
      this.checkFutureSubscriptionFirstSuccess(response);
    }, ko => {
      this.displayError(ko);
    });
  }

  private checkFutureSubscriptionFirstSuccess(response: ApiResponse<Subscription>) {
    if (response.code == 200) {
      this.setFutureSubscription(response.data);
    } else if (response.code == 404) {
      this.startFutureSubscription();
    } else {
      this.displayError(response);
    }
  }

  private setFutureSubscription(subscriptionDto: Subscription) {
    this.futureSubscription = subscriptionDto;
    this.loadHistory();
  }

  private startFutureSubscription() {
    this.premium.startFutureSubscription().subscribe(response => {
      this.checkFutureSubscriptionSecond();
    }, ko => {
      this.startFutureSubscriptionFailure(ko);
    })
  }

  private startFutureSubscriptionFailure(ko: any) {
    if (ko.error.ErrorType == 'SubscriptionAlreadyStarted') {
      this.loadHistory()
    } else {
      this.displayError(ko);
    }
  }

  private loadHistory() {
    this.premium.getHistory().subscribe(response => {
      this.setHistory(response.data);
    }, ko => {
      this.displayError(ko);
    });
  }

  private setHistory(data: Subscription[]) {
    var sorted = this.sortHistory(data);
    sorted.forEach(subscription => {
      this.allSubscriptions.push({
        id: subscription.id,
        price: subscription.price,
        startingDate: subscription.begin.substring(0, 10),
        endingDate: subscription.end.substring(0, 10),
        status: subscription.status
      });
    });

    this.tryToSetCurrentSubscription();
  }
  
  private sortHistory(history: Subscription[]): Subscription[] {
    return history.sort((a, b) => a.begin < b.end ? -1 : 1);
  }

  private tryToSetCurrentSubscription() {
    this.setCurrentSubscription();
  }

  private setCurrentSubscription() {
    if (this.allSubscriptions && this.allSubscriptions.length > 0) {
      var actives = this.allSubscriptions.filter(s => s.status == Subscription.STATUS_ACTIVE);
      if (actives.length != 1) {
        return;
      }

      this.currentSubscription = actives[0];
      this.allowUserToAccessPremium();
    }
  }

  private allowUserToAccessPremium() {
    var user = this.storage.enableUserPremium();
  }

  private checkFutureSubscriptionSecond() {
    this.premium.getFutureSubscription().subscribe(response => {
      this.setFutureSubscription(response.data);
    }, ko => {
      this.displayError(ko);
    });
  }

  private displayError(e: any) {
    LogsService.Error(e);
    this.toastsService.error(this.translateService.instant('premium.subscription.error_title'));
  }

  anySubscriptions(): boolean {
    return this.allSubscriptions && this.allSubscriptions.length > 0;
  }

  doPayment() {
    if (!this.submitted && this.futureSubscription && ((this.invoiceNeeded && this.form.valid) || this.invoiceNeeded == false)) {

      this.submitted = true;

      var amount = this.futureSubscription.price;
      var subscriptionId = this.futureSubscription.id;

      var user = this.storage.loadAuthenticatedUser();
      var email = user.email;
      var userId = this.storage.getUserIdFromToken();

      var link = this.paymentLinkGenerator.create(amount, userId, subscriptionId, email);

      if(!this.invoiceNeeded) {
        window.location.href = link;
      }
      else {

        var details = new InvoiceData();
        details.subscriptionId = subscriptionId;
        details.nip = this.form.get('nip').value;
        details.name = this.form.get('name').value;
        details.address = this.form.get('address').value;
        details.zip = this.form.get('zip').value;
        details.city = this.form.get('city').value;

        this.premium.setInvoiceData(details).subscribe(response => {
          window.location.href = link;
        }, ko => {
          this.displayError(ko);
        });
      }      
    }
    this.submitted = false;
  }

  shouldShowPayemntLink(): boolean {
    return this.futureSubscription != null;
  }

  price(): string {
    if (this.futureSubscription) {
      return this.futureSubscription.price;
    }
  }

  shouldShowSubscriptionActive(): boolean {
    return this.currentSubscription != null;
  }

  currentSubscriptionFrom(): string {
    if (this.currentSubscription) {
      return this.currentSubscription.startingDate;
    }
  }

  currentSubscriptionTo(): string {
    if (this.currentSubscription) {
      return this.currentSubscription.endingDate;
    }
  }

  currentSubscriptionPrice(): string {
    if (this.currentSubscription) {
      return this.currentSubscription.price;
    }
  }

  getClassForStatus(status: string): string {
    if(status == Subscription.STATUS_NEW) return "label-inverse-success";
    if(status == Subscription.STATUS_ACTIVE) return "label-inverse-info";
    if(status == Subscription.STATUS_EXPIRED) return "label-inverse-default";
    return "";
  }

  private ToggleInvoiceNeeded(): void {
    this.invoiceNeeded = !this.invoiceNeeded;
  }
}
