import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '../local.storage.service';
import { AuthenticatedUserDto } from '../model';
import { ToastsService } from 'src/app/services/toasts.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UserProfileGuard implements CanActivate {

  constructor(
    private localStorageService: LocalStorageService, 
    private router: Router,
    private toasts: ToastsService,
    private translateService: TranslateService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let user = this.localStorageService.loadAuthenticatedUser();
    if (this.isUserProfileSet(user)) {
      return true;
    } else {
      this.toasts.warning(this.translateService.instant('text.fill_user_profile'));
      this.router.navigate(['/user']);
      return false;
    }
  }

  private isUserProfileSet(user: AuthenticatedUserDto): boolean {
    return user &&
      user.name !== undefined &&
      user.name !== 'undefined'
      // user.phoneNumber !== undefined &&
      // user.phoneNumber !== 'undefined'
      ;
  }
}