<div class="page-body">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12" style="margin-top: 50px !important">
        <div *ngIf="shouldShowPayemntLink()" class="row">
          <div class="col-sm-6 offset-sm-3">
            <div class="card">
              <div class="card-header">
                <h5>{{'premium.subscription.title' | translate}}</h5>
              </div>
              <div class="row card-block">
                <div class="col-sm-12">
                  <span>{{'premium.subscription.text_intro' | translate}}</span>
                  <ul>
                    <li><b>{{'text.availability' | translate}}</b></li>
                  </ul>
                  <br />
                  <span>{{'premium.subscription.text_details' | translate}}{{price()}}</span>
                </div>
                <div class="col-sm-12">
                  <div class="form-group form-primary">
                    <br />
                    <input type="checkbox" class="form-group"
                      (click)="ToggleInvoiceNeeded()">{{'premium.subscription.invoice.needed' | translate}}
                  </div>
                </div>
                <div class="col-sm-12" *ngIf="invoiceNeeded">
                  <form [formGroup]="form" class="md-float-material form-material">
                    <div class="form-group form-primary">
                      <input formControlName="nip" type="text" name="user-nip" class="form-control" required="">
                      <div class="messages text-danger"
                        *ngIf="form.controls.nip.touched && form.controls.nip.errors?.required">
                        {{'premium.subscription.invoice.required' | translate}}</div>
                      <span class="form-bar"></span>
                      <label class="float-label">{{'premium.subscription.invoice.nip' | translate}}</label>
                    </div>
                    <div class="form-group form-primary">
                      <input formControlName="name" type="text" name="invoice-name" class="form-control" required="">
                      <div class="messages text-danger"
                        *ngIf="form.controls.name.touched && form.controls.name.errors?.required">
                        {{'premium.subscription.invoice.required' | translate}}</div>
                      <span class="form-bar"></span>
                      <label class="float-label">{{'premium.subscription.invoice.name' | translate}}</label>
                    </div>
                    <div class="form-group form-primary">
                      <input formControlName="address" type="text" name="user-address" class="form-control" required="">
                      <div class="messages text-danger"
                        *ngIf="form.controls.address.touched && form.controls.address.errors?.required">
                        {{'premium.subscription.invoice.required' | translate}}</div>
                      <span class="form-bar"></span>
                      <label class="float-label">{{'premium.subscription.invoice.address' | translate}}</label>
                    </div>
                    <div class="form-group form-primary">
                      <input formControlName="zip" type="text" name="user-zip" class="form-control" required="">
                      <div class="messages text-danger"
                        *ngIf="form.controls.zip.touched && form.controls.zip.errors?.required">
                        {{'premium.subscription.invoice.required' | translate}}</div>
                      <span class="form-bar"></span>
                      <label class="float-label">{{'premium.subscription.invoice.zip' | translate}}</label>
                    </div>
                    <div class="form-group form-primary">
                      <input formControlName="city" type="text" name="user-city" class="form-control" required="">
                      <div class="messages text-danger"
                        *ngIf="form.controls.city.touched && form.controls.city.errors?.required">
                        {{'premium.subscription.invoice.required' | translate}}</div>
                      <span class="form-bar"></span>
                      <label class="float-label">{{'premium.subscription.invoice.city' | translate}}</label>
                    </div>
                  </form>
                </div>
              </div>
              <div class="card-footer">
                <div class="media">
                  <div class="row">
                    <div class="col-sm-12">
                      <input type="submit" class="btn btn-success" (click)="doPayment()"
                        value="{{'premium.subscription.pay_action' | translate}}">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="shouldShowSubscriptionActive()">
          <div class="col-sm-6 offset-sm-3">
            <div class="card">
              <div class="card-header">
                <h5>{{'premium.subscription.title' | translate}}</h5>
              </div>
              <div class="row card-block">
                <div class="col-sm-12">
                  <span>{{'premium.subscription.text_current_intro' | translate}}</span>
                  <ul>
                    <li><b>{{'premium.subscription.text_current_from' | translate}}{{currentSubscriptionFrom()}}</b>
                    </li>
                    <li><b>{{'premium.subscription.text_current_to' | translate}}{{currentSubscriptionTo()}}</b></li>
                    <li><b>{{'premium.subscription.text_current_price' | translate}}{{currentSubscriptionPrice()}}</b>
                    </li>
                  </ul>
                  <br />
                </div>
              </div>
              <div class="card-footer">
                <div class="media">
                  <div class="row">
                    <div class="col-sm-12">
                      <b>{{'premium.subscription.text_thank_you' | translate}}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <!-- List view card start -->
            <div class="card">
              <div class="card-header">
                <h5>{{'premium.subscription.text_list_of_subscriptions' | translate}}</h5>

              </div>
              <div class="row card-block">
                <div class="col-md-12">
                  <div class="card-loader" *ngIf="loadCard"><i class="fa fa-spinner rotate-refresh"></i></div>
                  <table class="table table-hover table-responsive-md">
                    <thead>
                      <th>{{'premium.subscription.history.starting_date' | translate}}</th>
                      <th>{{'premium.subscription.history.ending_date' | translate}}</th>
                      <th>{{'premium.subscription.history.price' | translate}}</th>
                      <th>{{'premium.subscription.history.status' | translate}}</th>
                    </thead>
                    <tbody>
                      <tr *ngIf="!anySubscriptions()">
                        <td>{{'premium.subscription.text_no_subscriptions' | translate}}</td>
                      </tr>
                      <tr *ngFor="let s of allSubscriptions">
                        <td>{{s.startingDate}}</td>
                        <td>{{s.endingDate}}</td>
                        <td>{{s.price}}</td>
                        <td><label class="label"
                            [ngClass]="getClassForStatus(s.status)">{{'dict.' + s.status | translate}}</label></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- List view card end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>