import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicRegComponent } from './basic-reg.component';
import { BasicRegRoutingModule } from './basic-reg-routing.module';
import { SharedModule } from '../../../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { UsersService } from 'src/app/services/users.service';
import { ToastyService, ToastyConfig, ToastyModule } from 'ng2-toasty';
import { ToastsService } from '../../../../services/toasts.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    CommonModule,
    // BasicRegRoutingModule,
    RouterModule,
    SharedModule
  ],
  declarations: [BasicRegComponent],
  providers: [UsersService, ToastsService]
})
export class BasicRegModule { }
